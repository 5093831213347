import React, { useState } from 'react';
import { useForm } from 'react-hubspot';

import * as general from '@/components/general/FormPages.styled';

import { Form, Button, Layout, BlurBox } from '@/components/general';
import { LocalKeys } from '@/utils/constants';
import * as styled from './Content.styled';

export const Content = () => {
  const isBrowser = () => typeof window !== 'undefined';
  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_TALK_TO_EXPERTS_FORM_ID,
  });
  const BUSINESS_TYPES = [
    'Software Developer',
    'Software Agency',
    'Business / Brand / Investor',
  ];
  const [businessType, setBusinessType] = useState<string | undefined>(
    undefined,
  );
  const pricingPlan = isBrowser()
    ? localStorage.getItem(LocalKeys.PRICING_PLAN)
    : '';
  const period = isBrowser() ? localStorage.getItem(LocalKeys.APP_PERIOD) : '';

  return (
    <Layout $flexGrow>
      <BlurBox $maxHeightNone>
        {businessType ? (
          <>
            <general.Title>Request a demo</general.Title>
            <general.UnderTitle>
              Get a free consultation from out experts before getting started.
              <br />
              We are happy to help you choose the right solution, and collect
              your feedback about our platform.
            </general.UnderTitle>
            <styled.FormBox>
              <Form
                id="talk-to-experts"
                inputs={[
                  {
                    placeholder: 'Subject',
                    name: 'subject',
                    value: 'Request a demo',
                    hidden: true,
                  },
                  {
                    placeholder: 'Selected plan',
                    name: 'selected_plan',
                    value: `${pricingPlan} / ${period}`,
                    hidden: true,
                  },
                  {
                    placeholder: 'Business type',
                    name: 'business_type',
                    value: businessType,
                    hidden: true,
                  },
                  { placeholder: 'Name*', name: 'firstname' },
                  { placeholder: 'Company Name*', name: 'company' },
                  {
                    placeholder: 'Work email*',
                    type: 'email',
                    name: 'email',
                  },
                  { placeholder: 'Job Title*', name: 'jobtitle' },
                ]}
                formData={{
                  data: formData.data,
                  handleSubmit: formData.handleSubmit,
                  isLoading: formData.isLoading,
                }}
                textareaText="Tell us more about your company and project"
                buttonText="Let's Talk"
              />
            </styled.FormBox>
          </>
        ) : (
          <>
            <general.Title>👋 Hi there</general.Title>
            <general.UnderTitle>
              Choose what describes you best
            </general.UnderTitle>
            <styled.ChoiceBox>
              {BUSINESS_TYPES.map((business) => (
                <Button
                  key={business}
                  onClick={() => setBusinessType(business)}
                >
                  {business}
                </Button>
              ))}
            </styled.ChoiceBox>
          </>
        )}
      </BlurBox>
    </Layout>
  );
};
