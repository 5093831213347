import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Request';
import { Footer, FooterVariants } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';

const Request = () => (
  <ThemeProvider>
    <Meta
      title="Request a demo | CodePlatform"
      description="Get a free consultation from out experts before getting started"
      url={Links.REQUEST}
      robots="noindex"
    />
    <GlobalStyle />
    <Wrapper>
      <Header currentpath={Links.REQUEST} />
      <Content />
      <Footer variant={FooterVariants.SHORT} />
    </Wrapper>
  </ThemeProvider>
);

export default Request;
