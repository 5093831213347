import styled from 'styled-components';

export const ChoiceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;

  & button,
  a {
    border-radius: 16px;
    width: 100%;
    height: 56px;
    font-size: 17px;
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
      font-size: 15px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
    margin: 30px auto 0;
  }
`;

export const FormBox = styled.div`
  margin: 50px auto 0;
  max-width: 400px;
  width: 100%;
`;
